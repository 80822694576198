.AddScreen {
  height: 100vh;
  overflow: scroll;
}
.addScreen-picker {
  border: 2px solid rgb(132, 185, 213);
  background-color: rgb(239, 250, 255);
  padding: 20px;
  border-radius: 6px;
  display: flex;
  position: relative;
}

.addScreen-pickerBox {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(27, 83, 112);
  font-size: 14px;
  cursor: pointer;
}

.addScreen-header {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 6px;
  margin: 20px 0px;
  padding: 20px;
  background-color: rgb(227, 227, 227);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.addScreen-box {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 6px;
  padding: 20px;
  background-color: rgb(227, 227, 227);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.addScreen-Detaild {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 6px;
  margin: 20px 0px;
  padding: 20px;
  background-color: rgb(227, 227, 227);
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.addScreen-Related {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 6px;
  margin: 20px 0px;
  padding: 20px;
  background-color: rgb(227, 227, 227);
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.addScreen-ImageUploaderBox1 {
  height: 30vh;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addScreen-ImageUploaderImg1{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  max-height: 150px;
}
.addScreen-ImageUploaderBox2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 12px;
  height: 47vh;
  color: gray;
}

.addScreen-homeImage1{
  width: 100%;
  object-fit: cover;
}
.addScreen-pickerIcon{
  position: absolute;
  top: 0; 
  left: 0;
}