.DashboardScreen-txt1{
    font-family: "Montserrat-SemiBold";
    font-size: 25px;
}

.DashboardScreen-txt2{
    color: gray;
}

.DashboardScreen-item{
    border: 1px solid rgb(222, 222, 222);
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgb(240, 242, 245);
    cursor: pointer;
    border-radius: 4px;
}

.DashboardScreen-itemtxt1{
    font-family: "Montserrat-SemiBold";
    font-size: 15px;
}

.DashboardScreen-itemtxt2{
    font-family: "Montserrat-Regular";
    font-size: 12px;
    color: grey;
}