.container-Centered {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
  height: 100%;
}
/* .container-Centered {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 69%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 69px 54px 69px 54px;
  border-radius: 12px;
} */
