body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'),url(../src/assets/font/Montserrat-Bold.ttf
  ) format('opentype');
}
@font-face {
  font-family: 'Montserrat-Light';
  src: local('Montserrat-Light'),url(../src/assets/font/Montserrat-Light.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'),url(../src//assets/font/Montserrat-Medium.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'),url(../src/assets/font/Montserrat-Regular.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'),url(../src/assets/font/Montserrat-SemiBold.ttf) format('opentype');
}

