.categoryScrn-ModalInput {
  display: flex;
  justify-content: center;
}
.categoryScrn-Txt1 {
  font-size: 28px;
  font-family: "Montserrat-Bold";
  text-align: center;
}
:where(.css-dev-only-do-not-override-xs08p8).ant-modal .ant-modal-content {
  padding: 30px 30px !important;
}
/* :where(.css-dev-only-do-not-override-xs08p8).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item {
  padding: 0px !important;
} */
.category-Modal {
  width: 100%;
  height: 100%;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.category-sbmt-Bttn {
  width: 120px;
  height: 40px;
  background-color: #DAA520;
  color: #ffff;
  border-radius: 20px;
}
.category-sbmt-Bttn:hover {
  color: #ffff !important;
}
.category-Cancel-Bttn {
  width: 120px;
  height: 40px;
  background-color: #ffffff;
  font-weight: 700;
  color: #DAA520;
  border-radius: 20px;
  margin-right: 10px;
}
@media screen and (max-width: 480px) {
  .category-Cancel-Bttn {
    display: none;
  }
}
