.Header_Container {
  width: 100%;
}

.Header_MainBox {
  width: 100%;
  top: 0;
}
.Header_MainBox2 {
  position: sticky;
  z-index: 100;
  width: 100%;
  top: 0;
}

.Header_SubBox {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  overflow: hidden;
}

@keyframes fadeInAnimation {
  0% {
    height: 20px;
  }
  100% {
    height: 100px;
  }
}

.Header_SubBox2 {
  display: none;
}

.Header_LogoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.Header_Logo {
  width: 100%;
  height: 45px;
  cursor: pointer;
  object-fit: contain;
}

.Header_IconBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.Header_timerBox {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  color: gray;
}

.Header_txt2 {
  font-size: 12px;
  font-family: "Noto_Sans_SemiBold_600";
}

.Header_txt3 {
  font-size: 12px;
  font-family: "Noto_Sans_SemiBold_600";
}

.Header_UserPopover {
  min-width: 200px;
}

.Header_avatar {
  height: 50px;
  width: 50px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Noto_Sans_Bold_700";
  font-size: 20px;
  overflow: hidden;
  margin-bottom: 10px;
  border: 1px solid rgb(219, 219, 219);
}

.Header_avatarImg {
  height: 50px;
  width: 50px;
  border-radius: 100px;
}

.Header_txt4 {
  font-family: "Noto_Sans_SemiBold_600";
}

.Header_Logotxt {
  margin-top: 5px;
  color: gray;
  font-size: 12px;
  letter-spacing: 1px;
}

.Header_Icons {
  cursor: pointer;
}

.Header_txt5 {
  font-family: "Noto_Sans_Medium_500";
  font-size: 11px;
  text-decoration: none;
  color: #696969;
}

.Header_txt5:hover {
  cursor: pointer;
  color: #DAA520;
}

.Header_space {
  margin: 5px;
}

.Header_Icons3 {
  cursor: pointer;
}

.Header_txt6 {
  font-size: 10px;
}

.ProfileItem_box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  min-width: 140px;
}

.ProfileItem_txt1 {
  font-family: "Noto_Sans_Bold_700";
  margin-top: 10px;
  font-size: 16px;
  margin-bottom: 10px;
}

.ProfileItem_txt2 {
  font-family: "Noto_Sans_Medium_500";
  font-size: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  color: rgb(33, 66, 198);
}

.ProfileItem_txt1 {
  font-family: "Noto_Sans_Medium_500";
  /* font-size: 21px; */
  font-weight: 900;
  margin-top: 5px;
}

.ProfileItem_btn1 {
  background-color: #DAA520;
  color: #fff;
}

/*  ---------------- Drawer Styles ----------------  */

.DrawerModal_img {
  width: 250px;
  height: 50px;
  object-fit: contain;
}

.DrawerModal_txt1 {
  font-family: "Noto_Sans_Medium_500";
  font-size: 12px;
  text-decoration: none;
  display: flex;
  margin-left: 2px;
}

.DrawerModal_item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(227, 227, 227);
  margin-bottom: 10px;
  padding: 10px 0px;
}

.DrawerModal_txt2 {
  font-family: "Noto_Sans_Bold_700";
  font-size: 18px;
  text-transform: uppercase;
  color: #DAA520;
}

.DrawerModal_txt3 {
  font-family: "Noto_Sans_Medium_500";
  font-size: 14px;
  margin-bottom: 11px;
  text-transform: uppercase;
  cursor: pointer;
  color: #696969;
}

.DrawerModal_input {
  background-color: rgb(244, 244, 244);
  border-radius: 6px;
  padding: 5px;
}

.DrawerModal_txt3Dark:hover {
  color: rgb(255, 255, 255);
}

.DrawerModalCloseBtn1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.DrawerModal_Header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid rgb(223, 223, 223);
  margin-bottom: 10px;
}

.DrawerModal_HeaderLogo {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 7px;
}

.DrawerModal_HeaderBox1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.DrawerModal_HeaderBox2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.DrawerModal_HeaderBox3 {
  border: 1px solid rgb(227, 227, 227);
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.DrawerModal_txt4 {
  margin-bottom: 10px;
  padding: 15px 0px;
  font-family: "Noto_Sans_SemiBold_600";
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(227, 227, 227);
  cursor: pointer;
}
.DrawerModal_txt4:last-child {
  border-bottom: 0px;
}

.DrawerModal_box4 {
  border-right: 1px solid rgb(227, 227, 227);
  padding-right: 20px;
}

.DrawerModal_footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 50px;
  gap: 30px;
}

@media only screen and (min-width: 500px) {
  .DrawerModalCloseBtn2 {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .Header_SubBox {
    height: 68px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    overflow: hidden;
  }

  @keyframes fadeInAnimation {
    0% {
      height: 20px;
    }
    100% {
      height: 68px;
    }
  }

  .Header_SubBox2 {
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .Header_Container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .Header_Logo {
    height: 32px;
    object-fit: contain;
  }

  .Header_Icons2 {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    cursor: pointer;
    margin-right: 10px;
  }

  .Header_Icons {
    display: none;
  }

  .Header_Logotxt {
    margin-top: 3px;
  }

  .Header_space {
    margin: 0px;
  }

  .Header_Icons3 {
    margin-right: -10px;
  }

  .DrawerModal_input {
    padding: 0px;
  }

  .DrawerModal_img {
    width: 200px;
    height: 30px;
    object-fit: contain;
  }
  .DrawerModal_HeaderBox1 {
    padding-bottom: 20px;
  }

  .DrawerModalCloseBtn1 {
    display: none;
  }
  .DrawerModalCloseBtn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
  .DrawerModal_HeaderBox2 {
    padding-bottom: 20px;
  }
  .DrawerModal_HeaderBox3 {
    flex: 1;
    font-size: 14px;
  }
  .DrawerModal_box4 {
    padding-right: 0px;
    border-right: 0px;
  }
}
.SubHeader_box {
  height: 35px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.SubHeader_box2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 35px;
  cursor: pointer;
}
.SubHeader_txt1 {
  font-family: "Noto_Sans_Medium_500";
  font-size: 14px;
  border-bottom: 2px solid transparent;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  padding-top: 5px;
}
.details_box4 {
  display: flex;
}
.details_box1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.caption {
  width: 70%;
  font-family: "Noto_Sans_Medium_500";
  font-size: 14px;
  padding: 5px;
}
.discription {
  width: 30%;
  font-family: "Noto_Sans_Medium_500";
  display: flex;
  justify-content: end;
  font-size: 14px;
  padding: 5px;
}
.captionDiscription {
  width: 100%;
  height: 20px;
  display: flex;
}

.details_txt2 {
  font-family: "Noto_Sans_Medium_500";
  font-size: 12px;
  color: grey;
}
.details_txt3 {
  font-size: 14px;
  margin-bottom: 4px;
  margin-top: -5px;
}
.details_box {
  padding-right: 25px;
}
.details_txt1 {
  font-size: 30px;
  font-family: "Noto_Sans_SemiBold_600";
  line-height: 40px;
  margin-bottom: 16px;
}

.tags_Header {
  display: flex;
  width: 100%;
}

.page-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(234, 234, 234);
  margin-top: 20px;
}
.ShareBox {
  display: flex;
  flex-direction: row;
  font-size: 23px;
  cursor: pointer;
  font-family: "Noto_Sans_Medium_500";
}
.CommentBox_txt1 {
  font-family: "Noto_Sans_Medium_500";
}

.CommentBox_txt2 {
  font-family: "Noto_Sans_Medium_500";
  font-size: 12px;
}

.CommentBox_box {
  margin-top: 5px;
  float: right;
}

.CommentItem_box1 {
  border-top: 1px solid rgb(234, 234, 234);
  padding: 10px;
}

.CommentItem_box2 {
  display: flex;
  flex-direction: row;
}

.CommentItem_box3 {
  padding-inline: 40px;
}

.CommentItem_box4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CommentItem_box5 {
  display: flex;
  align-items: center;
}

.CommentItem_txt1 {
  font-family: "Noto_Sans_Medium_500";
}

.CommentItem_txt2 {
  font-family: "Noto_Sans_Medium_500";
  color: #454d5d;
}
.NewsItem3 {
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding-bottom: 12px;
  font-family: "Noto_Sans_Medium_500";
  font-size: 15px;
  cursor: pointer;
}
.NewsItem3Img {
  object-fit: fill;
  border-radius: 6px;
  width: 100%;
  aspect-ratio: 1.6/1;
  background-color: rgb(211, 211, 211);
  overflow: hidden;
}
.NewsItem3_itemBox1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.NewsItem3_itemhead {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 15px;
  font-family: "Noto_Sans_Medium_500";
}
.NewsItem3_itemBox2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.NewsItem3_itemtxt1 {
  font-family: "Montserrat-Light";
  font-size: 12px;
  color: gray;
  margin-right: 5px;
}
.newsItems1Box {
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding-bottom: 10px;
  font-family: "Noto_Sans_Medium_500";
  font-size: 15px;
  cursor: pointer;
}
.NewsItem2Img {
  width: 100%;
  aspect-ratio: 1.6/1;
  border-radius: 6px;
  object-fit: fill;
  background-color: grey;
}

.NewsItem2Txt1 {
  font-size: 20px;
  color: "black";
  padding-top: 10px;
  margin-bottom: 10px;
  font-family: "Noto_Sans_SemiBold_600";
}
.newsItemsTxt2 {
  font-family: "Montserrat-Light";
  font-size: 12px;
  color: gray;
  margin-right: 5px;
}

.newsItemsTxt3 {
  font-size: 12px;
  font-family: "Noto_Sans_Medium_500";
}
.details_box3 {
  position: sticky;
  top: 140px;
}
