.MediaLibrary-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6;
  cursor: pointer;
  position: relative;
}

.MediaLibrary-image:hover {
  border: 1px solid black;
  scale: 1;
}

.MediaLibrary-label {
  font-size: 15px;
  z-index: 999;
}

.category-table-card-image {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.ImagePicker-backgroud {
  border-radius: 6px;
  border: 2px dashed rgb(149, 149, 149);
  background-color: rgb(238, 238, 238);
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-align: center;
  color: grey;
  margin-bottom: 10px;
  padding: 25px;
  position: relative;
}

.imagePickerImg {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  object-fit: cover;
}

.ant-upload-wrapper {
  width: 470px !important;
  height: 296px !important;
}

.ant-upload-select {
  width: 470px !important;
  height: 296px !important;
}

.UploadBox {
  height: 300px;
  width: 300px;
}
.croppButton {
  padding: 25px;
  background-color: rgb(238, 238, 238);
  height: 300px;
  overflow-y: scroll;
}
.image-croppButton {
  width: 100%;
  height: 120px;
  object-fit: cover;
}
.image-croppButton img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.paginationImagePicker {
  width: 100%;
  display: flex;
  justify-content: end;
}
