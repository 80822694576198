.PreviewModal-img {
  aspect-ratio: 1.6/1;
  width: 100%;
  max-height: 500px;
  object-fit: fill;
  background-color: rgb(228, 228, 228);
}

.PreviewModal-box1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: -10px;
  cursor: pointer;
  font-size: 25px;
}

.PreviewModal-box2 {
  font-size: 12px;
  flex: 1;
  color: grey;
}

.PreviewModal-txt1 {
  font-size: 30px;
  font-weight: 900;
}
