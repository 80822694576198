.imagetext1 {
  font-family: "Montserrat-Bold";
  font-size: 18px;
  color: #3f3f3f;
}
.imageupload-box {
  width: 100%;
  height: 192px;
  border-radius: 7px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  object-fit: cover;
}

.image-delete-btn{
  position: absolute;
  z-index: 999;
  color: #fffcfc;
  top: -8px;
  right: -8px;
  cursor: pointer;
  background-color: red;
  border-radius: 100%;
  padding: 3px;
}

.imgUlder-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imgUpld-ImageCard {
  position: absolute;
  z-index: 100;
  text-align: center;
}

.imgUplr-inputFile {
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.closebutton {
  background: rgb(231, 231, 231);
  color: rgb(88, 88, 88);
  margin-top: 10px;
  width: 100%;
  font-family: "Montserrat-SemiBold";
  text-align: center;
  padding: 10px;
  border: 0px;
  border-radius: 2px;
}

.submitbuttion {
  background: linear-gradient(to right, #3f70d1, #3d59ca, #1648ed);
  color: rgb(255, 255, 255);
  margin-top: 10px;
  text-align: center;
  padding: 10px;
  font-family: "Montserrat-SemiBold";
  border: 0px;
  border-radius: 2px;
}

.imagePIckerCard-Txt {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  font-weight: 700;
  color: Grey;
}

.imagePIckerCard-Txt {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  font-weight: 700;
  color: Grey;
}

/* .imageUpload-headTxt {
  font-family: "Montserrat-Semibold";
  font-size: 20px;
  font-weight: 700;
  color: #2d3f50;
  margin-bottom: 26px;
}
.imageUpload-ImgPicker {
}
.imageUpload-ImgPicker input[type="file"] {
  font-size: 100px;
  position: absolute;
  font-family: "Montserrat-SemiBold";
  left: 0;
  top: 0;
  opacity: 0;
  height: 100%;
} */
.imageUplaod-Txt {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  font-weight: 700;
  color: Grey;
}

.ImageUploader-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6;
}

.MediaLibrary-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6;
  cursor: pointer;
}

.MediaLibrary-txt1 {
  font-family: "Montserrat-Bold";
  font-size: 24px;
}
