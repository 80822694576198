.LoginScreen-Lockmodal{
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.LoginScreen-Lockmodalimg{
    width: 100px;
    height: 100px;
}
.LoginScreen-Lockmodaltxt2{
    text-align: center;
    font-size: 12px;
}
.LoginScreen-Lockmodaltxt1{
    font-weight: 800;
}