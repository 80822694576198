
.routeBox{
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.Admin-Header{
  height: 60px;
  border-bottom: 1px solid rgb(217, 217, 217);
  background-color: #F0F2F5;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Admin-Headertxt1{
  font-family: "Montserrat-SemiBold";
  font-size: 20px;
  color: #DAA520;
}

.Admin-HeaderBox{
  display: flex;
  align-items: center;
  text-align: right;
  cursor: pointer;
}

.Admin-HeaderTime{
  background-color: #e0e6eb;
  padding: 3px 10px;
  margin-right: 20px;
  width: 120px;
  border-radius: 5px;
  margin-left: 20px;
}

.Sider-logoBox {
  height: 50px;
  border-bottom: 1px solid rgb(219, 219, 219);
  border-right: 1px solid rgb(219, 219, 219);
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.Sider-logo{
  width: 100%;
  height: 40px;
  object-fit: contain;
}

.menuItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 15px 0px;
  margin: 2px 10px;
  font-size: 12px;
  font-family: "Montserrat-SemiBold";
  border-bottom: 1px solid rgb(236, 236, 236);
  text-align: center;
}
.menuItem:hover {
  background-color: #e0e6eb;
  border-radius: 10px;
  border-bottom: 0px solid #e0e6eb;
  color: #DAA520;
}

.active {
  background-color: #e0e6eb;
  border-radius: 10px;
  border-bottom: 0px solid #e0e6eb;
  color: #DAA520;
}

.MobileDrawer-logo-box {
  width: 50px;
  height: 50px;
}
.MobileDrawer-logo-image {
  width: 50px;
  height: 50px;
}
.headerDiscriptioncanvas {
  font-family: "Montserrat-Regular";
  font-size: 13px;
  color: grey;
}
.username {
  font-size: 12px;
}
.popovercontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  margin: 10px;
}
