.PageHeaders{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgb(232, 232, 232);
    padding-bottom: 0px;
    margin-top: -5px;
}

.PageHeaders-box1{
    margin-right: 20px;
    font-size: 40px;
    margin-bottom: 5px;
    color: grey;
}

.PageHeaders-box2{
    font-family: "Montserrat-Bold";
    font-size: 20px;
    color: #DAA520;
}
.PageHeaders-txt1{
    font-size: 10px;
    color: #000;
}