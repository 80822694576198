.loginSection {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  

  .loginSection-box{
    width: 100%;
    min-width: 40%;
    padding: 20px;
    border-radius: 5px;
  }

  .loginSection-logobox{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loginSection-logo{
    width: 200px;
  }